export const firebaseConfig = {
  apiKey: "AIzaSyDye4Ko22Dh8Siwb1yETkAQrnCmEcOOrnw",
  authDomain: "laidbacklu-web.firebaseapp.com",
  projectId: "laidbacklu-web",
  storageBucket: "laidbacklu-web.appspot.com",
  messagingSenderId: "830552021583",
  appId: "1:830552021583:web:e8430bdbf6762a3f29a83a",
  measurementId: "G-EDN4QN39WS",
};

export const network = "mainnet";
export const tokenAddress = "0xac644987601456554272b296936d3c262a7a1fcf";

//export const network = "mumbai";
//export const tokenAddress = "0x61d34F7F2EAF0b3423455624B72fa1723f133D90";
// export const tokenAddress = "0x4d78BC4DF551d43D45d36b7E05265A2F90ba3bb6";
